/* globals Vue, VueResource, google, gtagpageview, MediaQuery, _ */
/* exports vm */
/* esnext */

'use strict';

Vue.config.debug = true;

const STATUS_NONE         =  0;
const STATUS_CLOSED       =  1;
const STATUS_CLOSED_TODAY =  2;
const STATUS_OPEN         =  4;
const STATUS_CLOSING_SOON =  8;
const STATUS_OPENING_SOON = 16;

function isSameDate(d1, d2) {
    if (d2 === undefined) {
        d2 = new Date();
    }
    return d1.getFullYear() == d2.getFullYear()
        && d1.getMonth() == d2.getMonth()
        && d1.getDate() == d2.getDate();
}

function isToday(date) {
    return isSameDate(date, new Date());
}

function isTomorrow(date) {
    var tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);
    return isSameDate(date, tomorrow);
}

function distanceBetweenCoordinates(lat1, lng1, lat2, lng2) {
    var p = 0.017453292519943295; // Math.PI / 180
    var c = Math.cos;
    var a = 0.5 - c((lat2 - lat1) * p)/2 +
            c(lat1 * p) * c(lat2 * p) *
            (1 - c((lng2 - lng1) * p))/2;
    return Math.round(12742 * Math.asin(Math.sqrt(a)) * 1000); // 2 * R; R = 6371 km; 1000 = convert km to m
}

function CustomMapLocationControl(map, callback) {
    var locationButton = document.createElement('div'),
        locationInner = document.createElement('span');

    locationButton.className = 'map-control map-control--locate';
    locationButton.appendChild(locationInner);

    google.maps.event.addDomListener(locationButton, 'click', function() {
        if (callback) {
            callback();
        }
    });

    return locationButton;
}

function CustomMapZoomControl(map) {

    var controlGroup = document.createElement('div');
    controlGroup.className = 'map-control-group';

    var zoomInButton = document.createElement('div');
    zoomInButton.className = 'map-control map-control--zoom-in';
    controlGroup.appendChild(zoomInButton);

    var zoomOutButton = document.createElement('div');
    zoomOutButton.className = 'map-control map-control--zoom-out';
    controlGroup.appendChild(zoomOutButton);

    google.maps.event.addDomListener(zoomInButton, 'click', function() {
      map.setZoom(map.getZoom() + 1);
    });

    google.maps.event.addDomListener(zoomOutButton, 'click', function() {
      map.setZoom(map.getZoom() - 1);
    });

    return controlGroup;
}

function CustomMarker(options) {
    this.position = options.position;
    this.offset = options.offset;
    this.customData = options.customData;
    this.setMap(options.map);
}

CustomMarker.prototype = new google.maps.OverlayView();

CustomMarker.prototype.draw = function() {

    var self = this,
        div  = this.div;

    if (!div) {

        div = this.div = document.createElement('div');
        div.style.position = 'absolute';
        div.className = 'map-marker';

        if (typeof(self.customData.html) !== 'undefined') {
            this.setContent(self.customData.html);
        }

        if (typeof(self.customData.zIndex) !== 'undefined') {
            this.setZIndex(self.customData.zIndex);
        }

        if (typeof(self.customData.markerId) !== 'undefined') {
            div.dataset.markerId = self.customData.markerId;
            div.id = 'map-marker-' + self.customData.markerId;
        }

        google.maps.event.addDomListener(div, 'click', function(/* event */) {
            google.maps.event.trigger(self, 'click');
        });
        google.maps.event.addDomListener(div, 'mouseover', function(/* event */) {
            google.maps.event.trigger(self, 'mouseover');
        });
        google.maps.event.addDomListener(div, 'mouseout', function(/* event */) {
            google.maps.event.trigger(self, 'mouseout');
        });

        var panes = this.getPanes();
        panes.overlayImage.appendChild(div);
    }

    var pointPos = this.position,
        point;

    if (this.offset) {
        pointPos = google.maps.geometry.spherical.computeOffset(this.position, this.offset.distance, this.offset.heading || 0);
    }
    point = this.getProjection().fromLatLngToDivPixel(pointPos);

    if (point) {
        div.style.left = point.x + 'px';
        div.style.top = point.y + 'px';
    }
};

CustomMarker.prototype.remove = function() {
    this.setMap(null);
    if (this.div) {
        this.div.parentNode.removeChild(this.div);
        this.div = null;
    }
};

CustomMarker.prototype.getPosition = function() {
    return this.position;
};

CustomMarker.prototype.setZIndex = function(zIndex) {
    if (this.div) {
        this.div.style.zIndex = zIndex;
    }
};

CustomMarker.prototype.setContent = function(html) {
    this.div.innerHTML = html;
};

const displayTime = function (time, simplify = false) {
    if (simplify && time.indexOf(':00') > -1) {
        time = time.replace(/^(\d?\d)[:](00)$/, '$1');
    }
    return time.replace(/^0([0-9])/, '$1');
};

(function(){
    var bus = new Vue();

    Vue.use(VueResource);

    Vue.filter('join', function (value, separator = '') {
        if (!value) {
            return '';
        }
        if (!Array.isArray(value)) {
            return value;
        }
        return value.join(separator);
    });

    Vue.filter('distance', function (value) {
        if (!value) {
            return '';
        }

        if (value >= 10000) {
            return (value/1000).toFixed(0) + 'km';
        }
        else if (value >= 1000) {
            return (value/1000).toFixed(1) + 'km';
        }
        else {
            return value + 'm';
        }
    });

    Vue.filter('phoneLink', function (value) {
        if (!value) {
            return '';
        }
        return 'tel:' + value.replace(/\s/g, '');
    });

    Vue.filter('dayName', function (day, texts) {

        var weekdays = [
            '',
            texts.monday,
            texts.tuesday,
            texts.wednesday,
            texts.thursday,
            texts.friday,
            texts.saturday,
            texts.sunday,
            texts.publicHolidays,
        ];

        return weekdays[day] || '';
    });

    Vue.filter('weekday', function (unix, texts) {

        var weekdays = [
            texts.sunday,
            texts.monday,
            texts.tuesday,
            texts.wednesday,
            texts.thursday,
            texts.friday,
            texts.saturday,
        ];

        var date = new Date(unix * 1000);

        return weekdays[date.getDay()];
    });

    Vue.filter('date', function (unix, texts) {

        var months = [
            texts.january, texts.february, texts.march, texts.april, texts.may, texts.june,
            texts.july, texts.august, texts.september, texts.october, texts.november, texts.december
        ];

        var date = new Date(unix * 1000),
            month = months[date.getMonth()],
            day = date.getDate();

        if (isToday(date)) {
            return `${texts.today}, ${day} ${month}`;
        }
        if (isTomorrow(date)) {
            return `${texts.tomorrow}, ${day} ${month}`;
        }

        return `${day} ${month}`;
    });

    Vue.filter('time', function (value, simplify = false) {
        return displayTime(value, simplify);
    });

    Vue.filter('openingTimes', function (times, simplify = false) {
        if (!(times && times[0] && times[1])) {
            return '';
        }

        var timeStart = times[0],
            timeEnd = times[1];

        timeStart = displayTime(timeStart, simplify);
        timeEnd = displayTime(timeEnd, simplify);

        return `${timeStart} – ${timeEnd}`;
    });

    Vue.filter('highlight', function (value, highlightPattern) {
        if (highlightPattern) {
            var qRegex = new RegExp(highlightPattern, 'ig');
            return value.replace(qRegex, function(match /* , a, b */){
                return ('<span class="highlight">' + match + '</span>');
            });
        }
        return value;
    });

    Vue.filter('directions', function (location) {
        if (!(location.lat && location.lng)) {
            return '';
        }
        return `https://www.google.com/maps/dir/?api=1&destination=${location.lat},${location.lng}`;
    });

    Vue.component('location-info', {
        name: 'location-info',
        props: ['location', 'position', 'now', 'show-closing', 'highlightPattern', 'highlighted', 'displayDistance', 'texts'],
        computed: {
            __ () {
                return this.texts;
            },
            distance () {
                return distanceBetweenCoordinates(this.position.lat, this.position.lng, this.location.lat, this.location.lng);
            },
            isOpen () {
                return this.location.status & STATUS_OPEN;
            },
            closingIn () {
                if (this.now && this.isOpen && this.location.closingTimeToday) {
                    var diff = Math.abs(this.now - this.location.closingTimeToday),
                        minutes = Math.floor((diff/1000)/60) + 1,
                        minutesMod = minutes % 60,
                        hours = Math.floor(minutes/60),
                        hoursRounded = Math.round(minutes/60);

                    // More than 60 minutes: display hours and minutes

                    if (hours >= 1) {

                        // 1-2h left: Display hours and minutes: 1h 15min, 2h 15min

                        if (hours <= 2) {
                            if (minutesMod > 0) {
                                return `${hours}${this.texts.timeDiffHours} ${minutesMod}${this.texts.timeDiffMinutes}`;
                            }
                            else {
                                return `${hours}${this.texts.timeDiffHours}`;
                            }
                        }

                        // More than 2h left: Display rounded hours: 3h

                        else {
                            return `${hoursRounded}${this.texts.timeDiffHours}`;
                        }
                    }

                    // Default: display minutes only

                    return `${minutes}${this.texts.timeDiffMinutes}`;
                }
            },
        },
        template: '#location-info'
    });

    Vue.component('exhibition', {
        name: 'exhibition',
        props: ['exhibition', 'showDates', 'showDays', 'showEvents', 'highlightCloseEvents', 'highlightPattern', 'categories', 'texts'],
        computed: {
            __ () {
                return this.texts;
            },
            category () {
                if (!this.categories) {
                    return;
                }
                var category = null;

                if (this.exhibition.category) {
                    var matches = this.categories.filter(category => category.id === this.exhibition.category);
                    if (matches.length) {
                        category = matches[0];
                    }
                }
                return category;
            },
            categoryTitle () {
                return this.category ? this.category.title : '';
            },
            categoryColor () {
                return this.category ? this.category.color : 'inherit';
            },
        },
        template: '#exhibition'
    });

    var vm = new Vue({

        el: '#app',

        data: {

            appVersion: '010',

            config: {},

            now: new Date(),
            nowInterval: null,

            searchInputValue: '',

            isSearchFormVisible: false,
            isInfoPanelVisible: false,
            isOpeningTimesPanelVisible: false,

            texts: {},

            defaultFilters: {
                'galleries': {
                    name:  'galleries',
                    label: 'galleries',
                    group: 'type',
                    promotions: true,
                    showif: 'filterLocationType',
                },
                'institutions': {
                    name:  'institutions',
                    label: 'institutions',
                    group: 'type',
                    promotions: true,
                    showif: 'filterLocationType',
                },
                'openings': {
                    name:  'openings',
                    label: 'openings',
                    showif: 'filterOpenings',
                },
                'open': {
                    name:  'open',
                    label: 'nowOpen',
                    showif: 'filterOpen',
                },
                'near': {
                    name:  'near',
                    label: 'nearMe',
                    showif: 'filterNear',
                },
            },

            defaultFilterStatus: null,

            filterStatus: {
                galleries: true,
                institutions: false,
                openings: false,
                events: false,
                open: false,
                near: false,
            },

            activeCategory: null,
            activeRegion: null,

            filtersHaveChanged: false,

            map: null,
            mapMarkers: [],
            highestMapZIndex: 0,
            mapWalkingDistance: 0, // 400, // meters

            isMapVisible: false,
            isAnimating: false,

            regions: [],
            locations: [],
            exhibitions: [],
            events: [],
            categories: [],
            promotions: [],

            regionsFetched: false,
            locationsFetched: false,
            exhibitionsFetched: false,
            openingsFetched: false,
            eventsFetched: false,
            categoriesFetched: false,
            promotionsFetched: false,

            mapLoaded: false,
            userPosition: null,
            watchingUserPosition: false,
            triggeredWatchingUserPosition: false,

            maxMarkerDistanceFromCenter: 20000, // m

            openLocation: null,
            openLocationOnLoad: null,
            locationOpenedOnLoad: null,
            highlightLocation: null,

            setActiveCategoryOnLoad: null,
            setActiveRegionOnLoad: null,

            Vue
        },

        filters: {

        },

        beforeMount () {

            // Load config from global object

            this.config = Vue.$appConfig;
            this.texts = Vue.$texts;

            // Add color

            var color = window.getComputedStyle(document.documentElement).getPropertyValue('--accent-color');
            this.config.color = color ? color : this.config.color;

            if (Vue.$openLocation) {
                this.openLocationOnLoad = Vue.$openLocation;
            }
            if (Vue.$activeCategory) {
                this.setActiveCategoryOnLoad = Vue.$activeCategory;
            }
            if (Vue.$activeRegion) {
                this.setActiveRegionOnLoad = Vue.$activeRegion;
            }

            // Fetch data from API

            this.fetchData();

            // Save default filter status as loaded

            this.defaultFilterStatus = JSON.parse(JSON.stringify(this.filterStatus));

            // Locate user on map

            bus.$on('/map/locate', () => {
                this.toggleWatchingOfUserPosition();
            });
        },

        mounted () {

            // Update time at regular interval (1 minute)

            this.nowInterval = setInterval(() => {
                this.now = new Date();
            }, 60000);

            // Register events

            document.addEventListener('keyup', this.handleEscapeKey);
            document.addEventListener('keyup', this.handleArrowKeys);
            window.addEventListener('resize', this.handleResize);
            this.handleResize();
        },

        beforeDestroy () {
            window.removeEventListener('resize', this.handleResize);
            document.removeEventListener('keyup', this.escapeKeyListener);
            document.removeEventListener('keyup', this.handleArrowKeys);

            clearInterval(this.nowInterval);
        },

        computed: {

            __ () {
                return this.texts;
            },

            announcement () {
                const heading = this.texts.announcement_heading.trim();
                const body = this.texts.announcement_body.trim();
                return (heading || body) ? {
                    heading: heading,
                    body: body
                } : false;
            },

            day () {
                // Monday-based, 1-based wekkday
                var day = this.now.getDay();
                return day === 0 ? 7 : day;
            },

            filters () {
                var filters = Object.assign({}, this.defaultFilters);
                return filters;
            },

            numActiveFilters () {
                return Object.values(this.filterStatus).reduce((sum, next) => sum + (next ? 1 : 0), 0);
            },

            currentState () {
                return {
                    // openLocation: this.openLocation,
                    filterStatus: this.filterStatus,
                    activeCategory: this.activeCategory,
                    activeRegion: this.activeRegion,
                    url: this.currentStateUrl,
                    title: this.currentStateTitle,
                };
            },

            currentStateUrl () {
                var url = '/';

                if (this.openLocation) {
                    url = this.openLocation.url;
                }
                else if (this.activeCategory) {
                    url = this.activeCategory.url;
                }
                else if (this.activeRegion) {
                    url = this.activeRegion.url;
                }

                return url;
            },

            currentStateTitle () {

                var pattern = this.config.titlePattern,
                    title = this.config.siteName;

                if (this.openLocation) {
                    title = this.openLocation.title;
                }
                else if (this.activeCategory) {
                    title = this.activeCategory.headline;
                }
                else if (this.activeRegion) {
                    title = this.activeRegion.title;
                }
                else {
                    pattern = this.config.titlePatternHome;
                }

                return (pattern || '').replace(/{title}/i, title);
            },

            dataFetched () {
                return this.regionsFetched &&
                       this.locationsFetched &&
                       this.exhibitionsFetched &&
                       this.eventsFetched &&
                       this.promotionsFetched &&
                       this.categoriesFetched;
            },

            isReady () {
                return this.dataFetched && this.mapLoaded;
            },

            appClassNames () {
                return [
                    {
                        'is-not-ready': !this.isReady,
                        'is-ready': this.isReady,
                        'is-animating': this.isAnimating,
                        'is-map-loaded': this.mapLoaded,
                        'is-search-visible': this.isSearchFormVisible,
                        'is-map-visible': this.isMapVisible,
                        'is-info-panel-visible': this.isInfoPanelVisible,
                        'is-opening-times-panel-visible': this.isOpeningTimesPanelVisible,
                        'is-location-detail-visible': !!this.openLocation,
                        'is-watching-user-position': !!this.watchingUserPosition,
                        'has-triggered-watching-user-position': !!this.triggeredWatchingUserPosition,
                        'has-announcement': !!this.announcement,
                    }
                ];
            },

            displayDistance () {
                return this.watchingUserPosition
            },

            isValidSearchQuery () {
                return (this.sanitizedSearchQuery && this.sanitizedSearchQuery.length >= 3);
            },

            searchTerms () {
                if (this.sanitizedSearchQuery) {
                    return this.sanitizedSearchQuery
                            .replace(/([\s.,;]+)/g,'§sep§')
                            .split('§sep§')
                            .filter(term => term.length >= 2);
                }
                return [];
            },

            highlightPattern () {
                return this.isSearchFormVisible && this.isValidSearchQuery ? '(' + this.searchTerms.join('|') + ')' : null;
            },

            sanitizedSearchQuery () {
                return this.searchInputValue ? this.searchInputValue.trim().toLowerCase() : '';
            },

            numLocations () {
                return this.locations ? this.locations.length : 0;
            },

            numResults () {
                return this.results && this.results.list ? this.results.list.length : 0;
            },

            messageEmptyResults () {
                if (this.isSearchFormVisible) {
                    if (this.isValidSearchQuery) {
                        return this.texts.emptyResultListSearch;
                    }
                    else if (this.sanitizedSearchQuery) {
                        return this.texts.searchTermTooShort;
                    }
                    else {
                        return '';
                    }
                }

                return this.texts.emptyResultList;
            },

            results () {

                if (!this.dataFetched) {
                    return {
                        list:  [],
                        count: 0,
                        type:  null,
                        locations: [],
                        filterExhibitions: false,
                    };
                }

                var results = [],
                    resultsType = null,
                    locationTypes = [],
                    filters = [],
                    sort = [],
                    locations = [],
                    exhibitionsAreFiltered = false;

                // Collect allowed types of locations

                if (this.config.viewSettings.filterLocationType) {
                    if (this.isFilterActive('galleries')) {
                        locationTypes.push('gallery');
                    }
                    if (this.isFilterActive('institutions')) {
                        locationTypes.push('institution');
                    }
                }
                else {
                    locationTypes.push('gallery');
                    locationTypes.push('institution');
                }

                // Search: only filter by search terms

                if (this.isSearchFormVisible) {

                    resultsType = 'exhibition-list';

                    if (this.isValidSearchQuery) {
                        results = this.locationsHydrated;
                        results = results.filter((location) => {
                            var found = true;
                            this.searchTerms.forEach((term) => {
                                found = found && location.searchIndex.indexOf(term) >= 0;
                            });
                            return found;
                        });
                        locations = results;
                    }
                }

                // Category: only filter by event category

                else if (this.activeCategory) {

                    resultsType = 'exhibition-list';

                    results = [];

                    this.locationsHydrated.forEach((location) => {
                        var categoryExhibitions = location.exhibitions.filter((exhibition) => this.activeCategory.id === exhibition.category);
                        if (categoryExhibitions.length) {
                            location.filteredExhibitions = categoryExhibitions;
                            results.push(location);
                        }
                    });

                    locations = results;
                    exhibitionsAreFiltered = true;
                }

                // Openings: group by day and then by gallery

                else if (this.isFilterActive('openings')) {
                    resultsType = 'event-list';
                    results = {};

                    // Check for type of location

                    filters.push(locationGroup => locationTypes.indexOf(locationGroup.location.type) !== -1);
                    if (this.activeRegion) {
                        filters.push(locationGroup => locationGroup.location.region === this.activeRegion.id);
                    }

                    // Near me: sort by distance

                    if (this.isFilterActive('near')) {
                        filters.push((locationGroup) => locationGroup.location.lat && locationGroup.location.lng);
                        sort.push((a, b) => (a.location.distance > b.location.distance) ? 1 : ((b.location.distance > a.location.distance) ? -1 : 0));
                    }
                    else {
                        sort.push((a, b) => (a.location.sort > b.location.sort) ? 1 : ((b.location.sort > a.location.sort) ? -1 : 0));
                    }

                    // Exclude days without events

                    filters.push(locationGroup => locationTypes.indexOf(locationGroup.location.type) !== -1);

                    this.eventsHydrated.forEach((event) => {
                        var d = event.dates.day,
                            l = event.location.id;

                        if (!(d in results)) {
                            results[d] = {
                                day: d,
                                id: d,
                                locations: {},
                            };
                        }
                        if (!(l in results[d].locations)) {
                            results[d].locations[l] = {
                                id: event.location.id,
                                location: event.location,
                                events: [],
                            };
                        }
                        results[d].locations[l].events.push(event);
                    });

                    // Turn into Array and reset indices via filter()
                    results = Object.values(results).filter(() => true);
                    results = results.map((dayGroup) => {
                        dayGroup.locations = Object.values(dayGroup.locations).filter(() => true);

                        // Apply filters & sort
                        dayGroup.locations = filters.reduce((accumulator, current) => accumulator.filter(item => current(item)), dayGroup.locations);
                        dayGroup.locations = sort.reduce((accumulator, current) => accumulator.sort(current), dayGroup.locations);

                        return dayGroup;
                    });

                    results = results.filter(dayGroup => dayGroup.locations.length > 0);
                    locations = Array.prototype.concat.apply([], results.map((dayGroup) => {
                        return dayGroup.locations.map((locationGroup) => locationGroup.location);
                    }));
                }

                // Otherwise, group by gallery alone

                else {

                    results = this.locationsHydrated;
                    resultsType = 'exhibition-list';

                    // Filter by type of location

                    filters.push(location => locationTypes.indexOf(location.type) !== -1);
                    if (this.activeRegion) {
                        filters.push(location => location.region === this.activeRegion.id);
                    }

                    // Near me: sort by distance

                    if (this.isFilterActive('near')) {
                        filters.push(location => location.lat && location.lng);
                        sort.push((a, b) => (a.distance > b.distance) ? 1 : ((b.distance > a.distance) ? -1 : 0));
                    }

                    // Open now: filter by opening times status and exhibition status

                    if (this.isFilterActive('open')) {
                        filters.push(location => location.status & STATUS_OPEN);
                        filters.push(location => location.currentExhibitions.length > 0);
                        sort.push((a, b) => (a.closingTimeToday > b.closingTimeToday) ? 1 : ((b.closingTimeToday > a.closingTimeToday) ? -1 : 0));
                    }

                    // Apply filters

                    results = filters.reduce((accumulator, current) => {
                        return accumulator.filter(item => current(item));
                    }, results);

                    // Apply sort

                    sort.push((a, b) => (a.sort > b.sort) ? 1 : ((b.sort > a.sort) ? -1 : 0));

                    var customSort = function (a, b) {
                        var s = sort.reduce((accumulator, current) => {
                            return accumulator === 0 ? current(a, b) : accumulator;
                        }, 0);
                        return s;
                    };

                    results = results.sort(customSort);
                    locations = results;
                }

                // Insert promotions every n-th position

                if (this.promotionsVisible) {
                    var resultLength = results.length,
                        numAdded = 0, insertAt;
                    this.promotions.forEach((promotion, index) => {
                        insertAt = ((index+1) * 5) + numAdded;
                        if (insertAt < resultLength) {
                            promotion.isPromotion = true;
                            results.splice(insertAt, 0, promotion);
                            resultLength++;
                            numAdded++;
                        }
                    });
                }

                return {
                    list: results,
                    count: results.length,
                    type: resultsType,
                    locations: locations,
                    filterExhibitions: exhibitionsAreFiltered,
                };
            },

            visibleLocations () {
                var locations = [];
                if (this.mapLoaded && this.results && this.results.locations) {
                    locations = this.results.locations;
                    // Add location that was laoded on page load to make sure it's visible
                    if (this.locationOpenedOnLoad) {
                        locations = this.results.locations.concat([this.locationOpenedOnLoad]);
                    }
                    // Filter out promotions
                    locations = locations.filter(location => !location.isPromotion);
                }
                return locations;
            },

            nextLocation () {
                if (this.openLocation) {
                    var currentIndex = this.visibleLocations.findIndex(location => this.openLocation.id === location.id);
                    if (currentIndex > -1) {
                        return this.visibleLocations[currentIndex + 1] || null;
                    }
                }
                return null;
            },

            prevLocation () {
                if (this.openLocation) {
                    var currentIndex = this.visibleLocations.findIndex(location => this.openLocation.id === location.id);
                    if (currentIndex > -1) {
                        return this.visibleLocations[currentIndex - 1] || null;
                    }
                }
                return null;
            },

            currentPosition () {
                return this.userPosition ? this.userPosition : { lat: this.config.map.lat, lng: this.config.map.lng };
            },

            currentPositionLatLngObject () {
                return new google.maps.LatLng(this.currentPosition.lat, this.currentPosition.lng);
            },

            currentPositionRadiusLatLngObject () {
                // Offset user location center by walking distance radius southward
                return google.maps.geometry.spherical.computeOffset(this.currentPositionLatLngObject, this.mapWalkingDistance, 180);
            },

            locationsHydrated () {
                return this.locations.map((location) => {
                    location.distance = this.distanceToCurrentPosition(location.lat, location.lng);
                    location.exhibitions = this.exhibitionsHydrated.filter((exhibition) => exhibition.location && exhibition.location.id === location.id);
                    location.currentExhibitions = location.exhibitions.filter((exhibition) => exhibition.status === 'current');
                    location.upcomingExhibitions = location.exhibitions.filter((exhibition) => exhibition.status === 'upcoming');
                    location.filteredExhibitions = null;
                    location.hasHours = this.hasOpeningTimes(location);
                    location.status = this.openingTimesStatus(location);
                    location.statusFormatted = this.openingTimesStatusFormatted(location);
                    location.openingTimesToday = this.openingTimesToday(location);
                    location.closingTimeToday = this.closingTimeToday(location);
                    location.searchIndex = this.createSearchIndex(location);
                    return location;
                });
            },

            eventsHydrated () {
                return this.events.map((event) => {
                    event.exhibition = this.getExhibition(event.exhibition);
                    event.location = this.getLocation(event.location);
                    return event;
                });
            },

            exhibitionsHydrated () {
                return this.exhibitions.map((exhibition) => {
                    exhibition.events = this.events.filter(
                        (event) => event.type !== 'opening' && event.exhibition === exhibition.id
                    )
                    exhibition.location = this.getLocation(exhibition.location);
                    return exhibition;
                });
            },

            promotionsVisible () {
                var _ = this.filterStatus,
                    promotionsVisible = true;

                Object.values(this.filterStatus).forEach(() => {});

                if (
                    this.isSearchFormVisible ||
                    this.activeCategory ||
                    !this.config.viewSettings.promotions
                ) {
                    return false;
                }

                promotionsVisible = (this.promotions.length > 0) && Object.values(this.filters).reduce((acc, curr) => {
                    return acc && (curr.promotions || !this.isFilterActive(curr.name));
                }, promotionsVisible);

                return promotionsVisible;
            },
        },

        methods: {

            handleLogoClick(event) {
                if (this.config.viewSettings.infoOverlay) {
                    this.toggleInfoPanel();
                    if (event) event.preventDefault();
                }
            },

            toggleInfoPanel () {
                this.isInfoPanelVisible = !this.isInfoPanelVisible;
            },

            toggleOpeningTimesPanel () {
                this.isOpeningTimesPanelVisible = !this.isOpeningTimesPanelVisible;
            },

            toggleSearch () {
                if (this.isSearchFormVisible) {
                    this.hideSearch();
                }
                else {
                    this.showSearch();
                }
            },

            showSearch () {
                this.isSearchFormVisible = true;
                this.focusSearchInput();
            },

            hideSearch () {
                this.isSearchFormVisible = false;
                this.blurSearchInput();
                this.searchInputValue = '';
            },

            focusSearchInput () {
                this.$refs.searchInput.focus();
            },

            blurSearchInput () {
                this.$refs.searchInput.blur();
            },

            toggleCategory (category) {
                if (this.isCategoryActive(category)) {
                    this.activeCategory = null;
                }
                else {
                    this.activeCategory = category;
                }
            },

            isCategoryActive (category) {
                return this.activeCategory && this.activeCategory.id === category.id;
            },

            resetFilters () {
                for (const filterName in this.defaultFilterStatus) {
                    this.filterStatus[filterName] = this.defaultFilterStatus[filterName];
                }
            },

            setFilter (filter) {
                this.filterStatus[filter] = true;
            },

            unsetFilter (filter) {
                this.filterStatus[filter] = false;
            },

            toggleFilter (filter) {
                // Active category? Only disable and do nothing else
                // to effectively restore previous filter state
                if (this.activeCategory) {
                    this.resetFilters();
                    if (!this.filterStatus[filter]) {
                        this.filterStatus[filter] = true;
                    }
                    return;
                }
                this.filterStatus[filter] = !this.filterStatus[filter];
            },

            isFilterActive (filter, statusObject = null) {
                return !!(statusObject ? statusObject : this.filterStatus)[filter];
            },

            isFilterVisiblyActive (filter, statusObject = null) {
                return !this.activeCategory && this.isFilterActive(filter);
            },

            isFilterDisabled (filter) {
                return !this.canFilterBeToggled(filter);
            },

            canFilterBeToggled (filter) {

                // Category active: all filters can be toggled
                if (this.activeCategory) {
                    return true;
                }

                // Filters that belong to a filter group and are active
                // get disabled if they are the only active filter in that group
                // to prevent certain groups having no active filters at all

                if (this.isFilterActive(filter) && this.isDefaultFilter(filter)) {
                    var group = this.filters[filter].group;
                    if (group && this.numActiveFiltersByGroup(group) <= 1) {
                        return false;
                    }
                }

                return true;
            },

            isDefaultFilter (filter) {
                return !!(filter in this.defaultFilters);
            },

            isGroupFilter (filter) {
                return !!this.filters[filter].group;
            },

            filtersByGroup (group) {
                return Object.values(this.filters).filter((filter) => filter.group === group);
            },

            numActiveFiltersByGroup (group) {
                return this.filtersByGroup(group).reduce((acc, curr) => acc + (this.isFilterActive(curr.name) ? 1 : 0), 0);
            },

            getExhibition (exhibitionKey) {
                if (exhibitionKey && typeof exhibitionKey !== 'object') {
                    var exhibitions = this.exhibitions.filter((exhibition) => exhibition.id === exhibitionKey);
                    if (exhibitions.length) {
                        return exhibitions[0];
                    }
                    return null;
                }
                else {
                    return exhibitionKey;
                }
            },

            getLocation (locationKey) {
                if (locationKey && typeof locationKey !== 'object') {
                    var locations = this.locations.filter((location) => location.id === locationKey);
                    if (locations.length) {
                        return locations[0];
                    }
                    return null;
                }
                else {
                    return locationKey;
                }
            },

            createSearchIndex (location) {
                var index = [];
                index.push(location.title);
                index.push(location.address);

                location.exhibitions.forEach((exhibition) => {
                    index.push(exhibition.title);
                    index.push(exhibition.overline);
                    index.push(exhibition.subline);
                    index.push(exhibition.space);
                    index.push(exhibition.artists);
                });

                return this.sanitizeSearchIndex(index.join(' '));
            },

            sanitizeSearchIndex (str) {
                return str.replace(/[,.]*\s+[,.]*/g, ' ').toLowerCase();
            },

            minutes (str) {
                var time = str.split(':');
                return time[0]*60 + time[1]*1;
            },

            minutesNow () {
                // return 17*60+29*1;
                return this.now.getHours()*60+this.now.getMinutes();
            },

            openingTimesToday (location) {
                return location.hours[this.day.toString()];
            },

            closingTimeToday (location) {
                var hours = this.openingTimesToday(location);
                if (hours) {
                    var parts = hours[1].split(':'),
                        closing = new Date();
                    closing.setHours(parts[0]);
                    closing.setMinutes(parts[1]);
                    closing.setSeconds(0);
                    closing.setMilliseconds(0);
                    return closing;
                }
                return 0;
            },

            hasOpeningTimes (location) {
                var countArray = Array.isArray(location.hours) ? location.hours : Object.keys(location.hours);
                return countArray.length > 0;
            },

            openingTimesStatus (location) {
                var hours = this.openingTimesToday(location),
                    status = STATUS_NONE;

                if (hours) {
                    var minutesStart = this.minutes(hours[0]),
                        minutesEnd = this.minutes(hours[1]),
                        minutesNow = this.minutesNow();

                    if (minutesNow >= minutesStart && minutesNow < minutesEnd) {
                        status |= STATUS_OPEN;
                        if ((minutesEnd - minutesNow) < 60) {
                            status |= STATUS_CLOSING_SOON;
                        }
                    }
                    else {
                        status |= STATUS_CLOSED;
                    }
                }
                else if (this.hasOpeningTimes(location)) {
                    status |= STATUS_CLOSED;
                    status |= STATUS_CLOSED_TODAY;
                }

                return status;
            },

            openingTimesStatusFormatted (location) {
                if (location.status & STATUS_CLOSING_SOON) {
                    return this.texts.openingStatusClosingSoon;
                }
                if (location.status & STATUS_OPEN) {
                    return this.texts.openingStatusOpenNow;
                }
                if (location.status & STATUS_OPENING_SOON) {
                    return this.texts.openingStatusOpeningSoon;
                }
                if (location.status & STATUS_CLOSED_TODAY) {
                    return this.texts.openingStatusClosedToday;
                }
                if (location.status & STATUS_CLOSED) {
                    return this.texts.openingStatusClosed;
                }
            },

            handleResize () {

            },

            handleEscapeKey (event) {
                if (event.keyCode === 27) { // esc
                    if (this.openLocation) {
                        this.openLocation = null;
                    }
                    else if (this.isInfoPanelVisible) {
                        this.toggleInfoPanel();
                    }
                    else if (this.isSearchFormVisible) {
                        this.hideSearch();
                    }
                }
            },

            handleArrowKeys (event) {
                if (this.openLocation) {
                    if (event.keyCode === 37) { // left arrow
                        this.openPrevLocation();
                    }
                    if (event.keyCode === 39) { // right arrow
                        this.openNextLocation();
                    }
                }
            },

            handleSearchSubmit () {

            },

            dayName (date, short) {
                if (!date) {
                    return '';
                }

                if (date > 10000) {
                    date = new Date(date * 1000); // Unix timestamp
                }

                var weekdays = [
                    this.texts.sunday,
                    this.texts.monday,
                    this.texts.tuesday,
                    this.texts.wednesday,
                    this.texts.thursday,
                    this.texts.friday,
                    this.texts.saturday
                ];

                var dayName = weekdays[date.getDay()];

                return short ? dayName.substr(0,2) : dayName;
            },

            handleHttpError (response) {
                console.error('Error fetching data', response);
            },

            apiUrl (route) {
                return this.config.urls.api + route + '/';
            },

            fetchData () {
                this.fetchRegions();
                this.fetchLocations();
                this.fetchExhibitions();
                this.fetchEvents();
                this.fetchCategories();
                this.fetchPromotions();
            },

            fetchRegions () {
                this.$http.get(this.apiUrl('regions')).then((response) => {
                    if (response.ok && response.body) {
                        this.regions = response.body;
                        this.regionsFetched = true;
                    }
                    else {
                        this.handleHttpError(response);
                    }
                }, this.handleHttpError);
            },

            fetchLocations () {
                this.$http.get(this.apiUrl('locations')).then((response) => {
                    if (response.ok && response.body) {
                        this.locations = response.body;
                        this.locationsFetched = true;
                    }
                    else {
                        this.handleHttpError(response);
                    }
                }, this.handleHttpError);
            },

            fetchExhibitions () {
                this.$http.get(this.apiUrl('exhibitions')).then((response) => {
                    if (response.ok && response.body) {
                        this.exhibitions = response.body;
                        this.exhibitionsFetched = true;
                    }
                    else {
                        this.handleHttpError(response);
                    }
                }, this.handleHttpError);
            },

            fetchEvents () {
                this.$http.get(this.apiUrl('events')).then((response) => {
                    if (response.ok && response.body) {
                        this.events = response.body;
                        this.eventsFetched = true;
                    }
                    else {
                        this.handleHttpError(response);
                    }
                }, this.handleHttpError);
            },

            fetchCategories () {
                this.$http.get(this.apiUrl('categories')).then((response) => {
                    if (response.ok && response.body) {
                        this.categories = response.body;
                        this.categoriesFetched = true;
                    }
                    else {
                        this.handleHttpError(response);
                    }
                }, this.handleHttpError);
            },

            fetchPromotions () {
                this.$http.get(this.apiUrl('promotions')).then((response) => {
                    if (response.ok && response.body) {
                        this.promotions = response.body;
                        this.promotions.sort(() => Math.random() - 0.5);
                        this.promotionsFetched = true;
                    }
                    else {
                        this.handleHttpError(response);
                    }
                }, this.handleHttpError);
            },

            focusSearchInput () {
                if (this.$refs.searchInput) {
                    this.$refs.searchInput.focus();
                }
            },

            resetView () {
                this.hideSearch();
                this.openLocation = null;
                this.resetFilters();
                this.activeCategory = null;
                this.$refs.resultList.scrollTop = 0;
                this.isMapVisible = false;
                this.stopWatchingUserPosition();
                this.setMapToInitialBounds();
            },

            centerLocationOnMap (location) {
                if (this.map && location && location.marker) {
                    this.map.panTo(location.marker.getPosition());
                }
            },

            showLocationOnMap (location) {
                this.centerLocationOnMap(location);
                this.isMapVisible = true;
                this.openLocation = null;
                this.highlightMarker(location);
                setTimeout(() => {
                    this.highlightMarkerOff(location);
                }, 3000);
            },

            distanceBetweenCoordinates (lat1, lng1, lat2, lng2) {
                return distanceBetweenCoordinates(lat1, lng1, lat2, lng2);
            },

            distanceToCurrentPosition (lat, lng) {
                return this.distanceBetweenCoordinates(lat, lng, this.currentPosition.lat, this.currentPosition.lng);
            },

            createMarkerMarkup (location) {
                return `<div class="location-label location-type--${location.type}" data-length="${(location.label || '').length}"><span>${location.label}</span></div>`;
            },

            moveMarkerToFront (location) {
                if (location && location.marker) {
                    this.highestMapZIndex += 1;
                    location.marker.setZIndex(this.highestMapZIndex);
                }
            },

            setMarkerState (location, state, unset = false) {
                if (location && location.marker && location.marker.div) {
                    location.marker.div.classList[unset ? 'remove' : 'add']('is-' + state);
                    this.moveMarkerToFront(location);
                }
            },

            unsetMarkerState (location, state) {
                this.setMarkerState(location, state, true);
            },

            hoverMarker (location) {
                this.setMarkerState(location, 'hovered');
                this.highlightLocation = location.id;
            },

            hoverMarkerOff (location) {
                this.unsetMarkerState(location, 'hovered');
                this.highlightLocation = null;
            },

            highlightMarker (location) {
                this.setMarkerState(location, 'highlighted');
            },

            highlightMarkerOff (location) {
                this.unsetMarkerState(location, 'highlighted');
            },

            activateMarker (location) {
                this.setMarkerState(location, 'active');
            },

            activateMarkerOff (location) {
                this.unsetMarkerState(location, 'active');
            },

            hideMarker (location) {
                this.setMarkerState(location, 'masked');
            },

            showMarker (location) {
                this.unsetMarkerState(location, 'masked');
            },

            setMapBounds (bounds) {
                if (!bounds) {
                    return;
                }

                var sw = new google.maps.LatLng(bounds.south, bounds.west);
                var ne = new google.maps.LatLng(bounds.north, bounds.east);
                var bounds = new google.maps.LatLngBounds(sw, ne);

                this.map.fitBounds(bounds);
                this.map.setCenter(bounds.getCenter());
                this.map.panToBounds(bounds);

                return bounds.getCenter();
            },

            setMapBoundsFromMarkers (markers) {
                if (!this.map || !markers.length) {
                    return;
                }

                var bounds = new google.maps.LatLngBounds();

                markers.forEach((marker) => {
                    var location = marker.customData.location;
                    bounds.extend(marker.position);
                });

                this.map.fitBounds(bounds);
                this.map.setCenter(bounds.getCenter());
                this.map.panToBounds(bounds);

                return bounds.getCenter();
            },

            setMapToInitialBounds () {
                return this.setMapBoundsFromMarkers(this.mapMarkers);
            },

            setupMap () {

                if (this.mapLoaded) {
                    return;
                }

                var that = this;

                this.highestMapZIndex = this.numLocations;

                var initialZoom = this.config.map.zoom || 14,
                    minZoom = 7;

                // Get map view config

                var center = new google.maps.LatLng(
                    parseFloat(this.config.map.lat),
                    parseFloat(this.config.map.lng)
                );

                // Map options

                var styles = {
                    default: this.config.map.style,
                    detail:  JSON.parse(JSON.stringify(this.config.map.style)).map((definition) => {

                        // Turn on local roads in detail view
                        if (definition.featureType === 'road.local' && definition.elementType === 'labels') {
                            definition.stylers = definition.stylers.map((styler) => {
                                if ('visibility' in styler) {
                                    styler.visibility = 'on';
                                }
                                return styler;
                            });
                        }
                        return definition;
                    })
                };

                var mapOptions = {
                    zoom: initialZoom,
                    minZoom: minZoom,
                    center: center,
                    mapTypeId: 'roadmap',
                    backgroundColor: 'transparent',
                    scrollwheel: false,
                    disableDefaultUI: true,
                    scaleControl: true,
                    styles: styles.default,
                    restriction: {
                        latLngBounds: {
                            north: 49.3,
                            south: 46.1,
                            east: 17.8,
                            west: 9
                        }
                    }
                };

                // Create map

                var map = new google.maps.Map(this.$refs.map, mapOptions);
                this.map = map;

                // Add custom controls

                var controlWrapper = document.createElement('div'),
                    zoomControl = new CustomMapZoomControl(map),
                    locationControl = new CustomMapLocationControl(map, () => {
                        bus.$emit('/map/locate');
                    });

                controlWrapper.className = 'map-control-wrapper';
                controlWrapper.appendChild(locationControl);
                controlWrapper.appendChild(zoomControl);

                controlWrapper.index = 1;
                map.controls[google.maps.ControlPosition.RIGHT_TOP].push(controlWrapper);

                // Set loaded state on first idle

                google.maps.event.addListenerOnce(map, 'idle', () => { this.mapLoaded = true; });

                // Add map markers

                var i = 0;

                for (var locationID in this.locations) {

                    var location = this.locations[locationID],
                        position = new google.maps.LatLng(
                            parseFloat(location.lat),
                            parseFloat(location.lng)
                        );

                    var marker = new CustomMarker({
                        position: position,
                        map: map,
                        customData: {
                            markerId: locationID,
                            location: location,
                            isHighlighted: false,
                            html: this.createMarkerMarkup(location),
                            zIndex: i+1,
                        }
                    });

                    this.mapMarkers[locationID] = marker;
                    location.marker = marker;

                    // Click on marker: open location info

                    google.maps.event.addListener(marker, 'click', function () {
                        that.openLocation = this.customData.location;
                    });

                    // Move marker to front when hovering/clicking

                    google.maps.event.addListener(marker, 'click', function() {
                        that.moveMarkerToFront(this.customData.location);
                    });
                    google.maps.event.addListener(marker, 'mouseover', function() {
                        that.moveMarkerToFront(this.customData.location);
                    });

                    // Highlight

                    google.maps.event.addListener(marker, 'mouseover', function() {
                        that.hoverMarker(this.customData.location);
                    });
                    google.maps.event.addListener(marker, 'mouseout', function() {
                        that.hoverMarkerOff(this.customData.location);
                    });

                    i++;
                }

                // Change style on zoom

                var currentStyle = 'default';

                google.maps.event.addListener(map, 'zoom_changed', function() {
                    var zoom = map.getZoom(),
                        newStyle = 'default';

                    if (zoom >= 16) {
                        newStyle = 'detail';
                    }
                    if (newStyle !== currentStyle) {
                        map.setOptions({styles: styles[newStyle]});
                        currentStyle = newStyle;
                    }
                });

                // this.createUserPositionMarker();

                // Register click event on map

                google.maps.event.addListener(map, 'click', function() {
                    bus.$emit('/map/clicked');
                });

                // Fit map to marker bounds

                this.setMapToInitialBounds();

                // Re-center on resize

                google.maps.event.addDomListener(window, 'resize', function() {
                    var center = map.getCenter();
                    google.maps.event.trigger(map, 'resize');
                    map.setCenter(center);
                });

                // Add overlay for rest of world
                // map.data.setStyle({
                //     fillColor: 'white',
                //     strokeWeight: 0,
                //     strokeColor: 'black',
                //     fillOpacity: 0.5
                // });

                // map.data.loadGeoJson('/site/templates/assets/dist/geo/overlay.geo.json');
            },

            toggleWatchingOfUserPosition () {
                if (this.watchingUserPosition) {
                    this.stopWatchingUserPosition();
                }
                else {
                    this.startWatchingUserPosition();
                }
            },

            stopWatchingUserPosition () {
                if (this.watchingUserPosition) {
                    navigator.geolocation.clearWatch(this.watchingUserPosition);
                    this.watchingUserPosition = false;
                    this.triggeredWatchingUserPosition = false;
                }
            },

            startWatchingUserPosition (silent) {

                if (this.watchingUserPosition) {
                    return;
                }

                if (window.location.protocol === 'https:' && navigator.geolocation) {
                    this.triggeredWatchingUserPosition = true;

                    this.watchingUserPosition = navigator.geolocation.watchPosition((position) => {
                        this.userPosition = {
                            lat: position.coords.latitude,
                            lng: position.coords.longitude,
                            heading: position.coords.heading,
                            accuracy: position.coords.accuracy
                        };
                        this.updateUserPositionMarker();
                        if (this.triggeredWatchingUserPosition) {
                            this.zoomInToUserPositionMarker();
                            this.triggeredWatchingUserPosition = false;
                        }
                    },
                    (error) => {
                        console.log(error);
                        if (!silent && typeof error === 'object') {
                            if (error.code === 1) {
                                window.alert('Geolocation failed: access was blocked by the user');
                            }
                            if (error.code === 2) {
                                window.alert('Geolocation failed: position of the user is unavailable');
                            }
                            if (error.code === 3) {
                                window.alert('Geolocation failed: process took to long to complete');
                            }
                        }
                        this.triggeredWatchingUserPosition = false;
                        this.watchingUserPosition = false;
                    },
                    {
                        timeout:    30000, // 30s
                        maximumAge: 15000, // 15s
                    });
                }
            },

            createUserPositionMarker () {
                if (!this.userPositionMarker) {

                    var zIndex = 999999;

                    this.userPositionMarker = new CustomMarker({
                        position: this.currentPositionLatLngObject,
                        map: this.map,
                        customData: {
                            markerId: 'user',
                            zIndex: zIndex,
                            html: '<div class="user-position-circle"></div><div class="user-position-accuracy"></div>',
                        }
                    });

                    if (this.mapWalkingDistance > 0) {

                        this.userPositionRadiusCircle = new google.maps.Circle({
                            center: this.currentPositionLatLngObject,
                            clickable: false,
                            draggable: false,
                            editable: false,
                            fillOpacity: 0,
                            map: this.map,
                            radius: this.mapWalkingDistance,
                            strokeColor: this.config.color,
                            strokeOpacity: 1,
                            strokeWeight: 1.25,
                            zIndex: zIndex,
                        });

                        this.userPositionRadiusMarker = new CustomMarker({
                            position: this.currentPositionLatLngObject,
                            offset: {
                                distance: this.mapWalkingDistance,
                                heading: 180,
                            },
                            map: this.map,
                            customData: {
                                markerId: 'radius',
                                zIndex: 0,
                                html: '<div class="user-position-radius-text">5 min walk</div>',
                            }
                        });

                    }
                }
                return this.userPositionMarker;
            },

            updateUserPositionMarker () {
                if (this.watchingUserPosition && this.userPosition) {
                    var marker = this.createUserPositionMarker(),
                        latLng = new google.maps.LatLng(this.userPosition.lat, this.userPosition.lng);
                    marker.position = latLng;
                    if (this.userPositionRadiusCircle) {
                        this.userPositionRadiusCircle.setOptions({center: latLng});
                    }
                    if (this.userPositionRadiusMarker) {
                        this.userPositionRadiusMarker.position = latLng;
                    }
                    this.map.panTo(latLng);
                }
            },

            zoomInToUserPositionMarker () {
                if (this.watchingUserPosition && this.userPosition) {
                    var marker = this.createUserPositionMarker(),
                        latLng = new google.maps.LatLng(this.userPosition.lat, this.userPosition.lng),
                        circle = new google.maps.Circle();

                    circle.setRadius(1000); // 1km
                    circle.setCenter(latLng);

                    marker.position = latLng;
                    this.map.panTo(latLng);
                    this.map.fitBounds(circle.getBounds());
                }
            },

            openPrevLocation () {
                if (this.prevLocation) {
                    this.openLocation = this.prevLocation;
                }
            },

            openNextLocation () {
                if (this.nextLocation) {
                    this.openLocation = this.nextLocation;
                }
            },
        },

        watch: {

            currentStateUrl () {

                history.replaceState(this.currentState, null, this.currentStateUrl);

                if(window.ga && ga.create && this.currentStateUrl && this.currentStateUrl !== '/') {
                    gtagpageview(this.currentStateUrl);
                }
            },

            currentStateTitle () {
                document.title = this.currentStateTitle;
            },

            isSearchFormVisible () {
                if (this.isSearchFormVisible) {
                    this.isMapVisible = false;
                    this.activeCategory = null;
                    this.setMapToInitialBounds();
                }
            },

            dataFetched () {
                if (this.dataFetched === true) {
                    this.setupMap();
                }
            },

            isReady () {

                // Animate loading unless entering directly via location or category

                if (
                    this.isReady && this.config.viewSettings.initialAnimations &&
                    !this.openLocationOnLoad && !this.setActiveCategoryOnLoad
                ) {
                    this.isAnimating = true;
                    setTimeout(() => {
                        this.isAnimating = false;
                    }, 4000);
                }
            },

            mapLoaded () {
                if (this.openLocationOnLoad) {
                    var matches = this.locationsHydrated.filter(location => location.id === this.openLocationOnLoad);
                    this.locationOpenedOnLoad = matches.length ? matches[0] : null;
                    if (this.locationOpenedOnLoad) {
                        this.openLocation = this.locationOpenedOnLoad;
                    }
                }
                if (this.setActiveCategoryOnLoad) {
                    var matches = this.categories.filter(category => category.id === this.setActiveCategoryOnLoad);
                    if (matches.length) {
                        this.activeCategory = matches[0];
                        this.setActiveCategoryOnLoad = null;
                    }
                }
                if (this.setActiveRegionOnLoad) {
                    var matches = this.regions.filter(region => region.id === this.setActiveRegionOnLoad);
                    if (matches.length) {
                        this.activeRegion = matches[0];
                        this.setActiveRegionOnLoad = null;
                    }
                }
            },

            activeCategory (newValue) {
                // Scroll result list to top after filter/content change
                this.$refs.resultList.scrollTop = 0;
            },

            activeRegion (newValue) {
                // Scroll result list to top after filter/content change
                this.$refs.resultList.scrollTop = 0;
                if (this.activeRegion && this.activeRegion.bounds) {
                    this.setMapBounds(this.activeRegion.bounds);
                } else {
                    var markers = this.visibleLocations.map(location => location.marker);
                    if (markers.length) {
                        this.setMapBoundsFromMarkers(markers);
                    } else {
                        this.setMapToInitialBounds();
                    }
                }
            },

            filterStatus: {
                handler: function(newValue) {
                    // Scroll result list to top after filter/content change
                    this.$refs.resultList.scrollTop = 0;

                    // Mark filters as changed
                    this.filtersHaveChanged = true;

                    this.activeCategory = null;
                },
                deep: true
            },

            'filterStatus.near': function (val) {
                if (val) {
                    this.startWatchingUserPosition(true);
                }
            },

            'filterStatus.openings': function (val) {
                if (val) {
                    this.unsetFilter('open');
                }
            },

            'filterStatus.open': function (val) {
                if (val) {
                    this.unsetFilter('openings');
                }
            },

            visibleLocations () {
                if (this.mapLoaded) {
                    this.locations.forEach((location) => {
                        this.hideMarker(location);
                    });
                    this.visibleLocations.slice().reverse().forEach((location) => {
                        this.showMarker(location);
                        this.moveMarkerToFront(location);
                    });
                }
            },

            openLocation (newLocation, oldLocation) {
                this.stopWatchingUserPosition();
                this.map.setZoom(16);

                this.activateMarkerOff(oldLocation);
                if (newLocation) {
                    this.activateMarker(newLocation);
                    this.centerLocationOnMap(newLocation);
                }

                if (this.locationOpenedOnLoad) {
                    if (!newLocation || newLocation.id !== this.locationOpenedOnLoad.id) {
                        this.locationOpenedOnLoad = null;
                    }
                }
            },
        }
    });

})();
